<template>
  <div class="projects content-wrapper">
    <ProjectImage imageName="IMG_0945"></ProjectImage>
    <ProjectImage imageName="IMG_0468"></ProjectImage>
    <ProjectImage imageName="IMG_1105"></ProjectImage>
    <ProjectImage imageName="IMG_0666"></ProjectImage>
    <ProjectImage imageName="IMG_0737"></ProjectImage>
    <ProjectImage imageName="IMG_0849"></ProjectImage>
  </div>
</template>

<script>
import ProjectImage from '@/components/ProjectImage.vue'

export default {
  name: 'Projects',
  components: {
    ProjectImage,
  },
}
</script>

<style lang="scss" scoped>
.projects {
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
}

@media (min-width: 800px) {
  .projects {
    grid-template-columns: 1fr 1fr;
  }
}
</style>
