<template>
  <div class="container">
    <img @click="onImageClick" class="image" :src="getImageURL(imageName, true)" />
    <template v-if="isOpen">
      <div class="preview-overlay"></div>
      <div class="preview">
        <div @click="onClose" class="close"></div>
        <img @click="onImageClick" class="image" :src="getImageURL(imageName, false)" />
      </div>
    </template>
  </div>
</template>

<script>
import VueScreenSize from 'vue-screen-size'

export default {
  name: 'projectImage',
  data() {
    return {
      isOpen: false,
    }
  },
  props: {
    imageName: String,
  },
  mixins: [VueScreenSize.VueScreenSizeMixin],
  methods: {
    getImageURL(imageName, compressed) {
      let path = 'images%2F'
      let format = 'jpg'
      if (compressed) {
        path = 'images%2Fthumbs%2F'
        imageName = `${imageName}_780x1040`
        format = 'jpeg'
      }

      return `https://firebasestorage.googleapis.com/v0/b/montocph-app.appspot.com/o/${path}${imageName}.${format}?alt=media`
    },
    onImageClick() {
      if (this.$vssWidth > 800) this.isOpen = true
    },
    onClose() {
      this.isOpen = false
    },
  },
}
</script>

<style lang="scss" scoped>
.image {
  cursor: pointer;
  max-width: 100%;
  height: auto;
}
.container {
  transition: all 0.3s ease;
}
.preview {
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 50%;
  width: 70vw;
  transform: translate(-50%, -50%);
  padding: 2rem;
  background: white;
}
.preview-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: table;
  transition: opacity 0.3s ease;
}
.close {
  position: absolute;
  right: 16px;
  top: 16px;
  width: 24px;
  height: 24px;
  opacity: 0.8;
  cursor: pointer;
  &:hover {
    opacity: 1;
  }
  &:before,
  &:after {
    position: absolute;
    left: 11px;
    content: ' ';
    height: 25px;
    width: 1px;
    background-color: #333;
  }
  &:before {
    transform: rotate(45deg);
  }
  &:after {
    transform: rotate(-45deg);
  }
}

@media (min-width: 820px) {
  .preview {
    width: 680px;
    height: 680px;
    padding: 3rem;
  }
}
</style>
